import { Controller } from "react-hook-form"
import RDatePicker, { registerLocale } from "react-datepicker"
import Icon from "components/Icon"
import If from "components/If"
import pt from "date-fns/locale/pt"
import classnames from "classnames"
import { setValue } from "./utils"

import "./DateTimeDefault.css"
import "./DateTime.styl"

registerLocale("pt-BR", pt)

const DateTime = ({
  name,
  showTime = false,
  form: { control },
  variant,
  onChange = (e) => e,
  placeholder = "Data",
  ...props
}) => {
  const format = showTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"

  return (
    <div
      className={classnames("form-date-picker", {
        [`form-date-picker--${variant}`]: variant,
      })}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange: fieldChange, ...field } }) => (
          <div className="form-date-picker__wrapper">
            <div
              className={classnames("form-date-picker__field", {
                [`form-date-picker__field--${variant}`]: variant,
              })}
            >
              <RDatePicker
                id={name}
                dateFormat={format}
                locale="pt-BR"
                selected={setValue(value)}
                autoComplete="off"
                onChange={(value) => {
                  fieldChange(value)
                  onChange({ target: { value }, currentTarget: { value } })
                }}
                placeholderText={placeholder}
                showTimeSelect={showTime}
                isClearable={true}
                disabledKeyboardNavigation
                {...field}
                {...props}
              />
            </div>
            <If condition={!value}>
              <label htmlFor={name}>
                <Icon className="form-date-picker__icon" name="calendar" />
              </label>
            </If>
          </div>
        )}
      />
    </div>
  )
}

export default DateTime
